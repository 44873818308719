<div class="login-container" *ngIf="showForm">
    <app-login-header [closeOnBack]="closeOnBack" [returnUrl]="returnUrl"></app-login-header>
    <mat-card class="login-card">
        <div class="subtitle">{{ 'login.subtitle' | translate:locale.language }}</div>
        <mat-card-header>
            <mat-card-title>
                <app-company-logo class="app-company-logo"></app-company-logo>
                <span>{{ 'login.title' | translate:locale.language }} {{companyName}}</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>

            <div class="google-container">
                <button mat-button mat-flat-button class="primary outline" (click)="loginByGoogle()" [ngClass]="{'loading': loadingByGoogle}">
                    <div class="google-icon" *ngIf="!loadingByGoogle"></div>
                    <span >{{ 'login.buttonGoogle' | translate:locale.language }}</span>
                </button>
            </div>

            <div class="separator">
                <mat-divider></mat-divider>
                <span>{{ 'login.separator' | translate:locale.language }}</span>
                <mat-divider></mat-divider>
            </div>

            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline" class="active form-field-outline-simple">
                    <input matInput appDisableAutoFocusIfMobile formControlName="username" placeholder="{{ 'login.username' | translate:locale.language }}">
                    <mat-error *ngIf="loginForm.controls.username.errors?.required">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                        {{ 'login.usernameRequired' | translate:locale.language }}
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls.username.errors?.email">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                        {{ 'login.usernameFormatRequired' | translate:locale.language }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="active form-field-outline-simple">
                    <input matInput 
                        [type]="showPassword ? 'text' : 'password'" 
                        formControlName="password" 
                        placeholder="{{ 'login.password' | translate:locale.language }}">
                    <button type="button" class="show-password-btn" mat-icon-button matSuffix (click)="showPassword = !showPassword">
                        <mat-icon fontSet="phr-iconset" [ngClass]="{'phr-iconset-eye': showPassword, 'phr-iconset-blind-eye': !showPassword}" class="phr-iconset-close-circle-line"></mat-icon>
                        <!--<mat-icon>{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>-->
                    </button>
                    <mat-error *ngIf="submitted && loginForm.controls.password.errors">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                        {{ 'login.passwordRequired' | translate:locale.language }}
                    </mat-error>
                </mat-form-field>
                <app-login-singup-error-banner [error]="error"></app-login-singup-error-banner>
                <div class="error-banner" *ngIf="alreadyExistsGoogleUser">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-alert"></mat-icon>
                    <div>
                        <span>{{ 'login.alreadyExistsUserText' | translate:locale.language }}
                        <a (click)="loginByGoogle()">{{ 'login.alreadyExistsGoogleUserLink' | translate:locale.language }}</a>
                    </span>
                    </div>                    
                </div>
                <div class="forgot-container">                
                    <a (click)="onForgotPassword()" [routerLink]="['./forgot-password']" [queryParams]="{ returnUrl: returnUrl, closeOnBack: closeOnBack, homeUrl: homeUrl }">{{ 'login.forgotPassword' | translate:locale.language }}</a>
                </div>
                <button mat-button mat-flat-button class="btn-login primary" [ngClass]="{'loading': loading }">
                    {{ 'login.buttonSubmit' | translate:locale.language }}
                </button>                
            </form>

            <div class="register-container">
                <span>{{ 'login.newUser' | translate:locale.language }}</span>
                <a (click)="onClickCreateAccount()">{{ 'login.createAccount' | translate:locale.language }}</a>
            </div>

            <app-cliniweb-directory-link></app-cliniweb-directory-link>
        </mat-card-content>
    </mat-card>
</div>
