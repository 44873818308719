import { Component, Inject, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-cliniweb-directory-link',
  templateUrl: './cliniweb-directory-link.component.html',
  styleUrls: ['./cliniweb-directory-link.component.css']
})
export class CliniwebDirectoryLinkComponent implements OnInit {

  numberOfDoctors = "+3K";
  doctors = [
    "https://cdn.aspstatic.com/Documentos/cusa/35/18_20200402031800.jpg",
    "https://cdn.aspstatic.com/Documentos/CAFE/101/107_20210328115529.jpg",
    "https://cdn.aspstatic.com/Documentos/CIF/1/22_20190719095606.jpg"
  ];

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) { }

  ngOnInit(): void {
  }

}
